:root {

	--slgo-main-orange: #eb6e17 !important;
	--slgo-main-blue: #006db4 !important;
	--slgo-main-green: #93bb1e !important;
	--slgo-main-yellow: #ffe000 !important;
	--slgo-main-black: #000000 !important;
	--slgo-main-white: #ffffff !important;
	--slgo-main-grey: #818181 !important;
	--slgo-main-skeleton-bg: #dadfe0 !important;

	--slgo-text: #323232 !important;
	--slgo-light-text: #ffffff !important;
	--slgo-dark-text: #000000 !important;
	--slgo-title: #000000 !important;
	--slgo-small-title: #1b4c90 !important;
	--slgo-blue-nav: #004c91 !important;
	--slgo-blue-footer: #004c91 !important;
	--slgo-blue-btn: #1b4c90 !important;
	--slgo-sky-btn: #007fc6 !important;
	--slgo-sky-bg: #007fc6 !important;
	--slgo-sky-form: #006db5 !important;
	--slgo-sky-badge: #006db5 !important;
	--slgo-sky-offcanvas: #006db5 !important;
	--slgo-sky-link: #006db5 !important;
	--slgo-sly-tab: #419dd4 !important;
	--slgo-grey-bg: #eaf0f1 !important;
	--slgo-green-bg: #93bb1e !important;
	--slgo-grey-element: #cbcbcb !important;
	--slgo-green-slide: #93bb1e !important;
	--slgo-yellow-slide: #ffe000 !important;
	--slgo-orange-slide: #eb6e17 !important;
	--slgo-grey-slide: #f1f1f1 !important;
	--slgo-red-required: #b30d0d !important;

}
body {
	min-height: 100vh;
	padding-top: 56px;
	font-variant-ligatures: no-common-ligatures !important;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 500 !important;
	color: var(--slgo-text);
	overflow-x: hidden;
	position: relative;
}
body strong, body b, body strong, .font-weight-bold, body strong *, body b *, body strong *, .font-weight-bold * {
	font-weight: 700 !important;
}
img {
	max-width: 100%;
}
a {
	text-decoration: none;
}
.slgo-text {
	color: var(--slgo-text) !important;
}
.slgo-light-text {
	color: var(--slgo-light-text) !important;
}
.slgo-dark-text {
	color: var(--slgo-dark-text) !important;
}
.slgo-title, .slgo-title * {
	color: var(--slgo-title) !important;
}
.slgo-pre-title {
	color: var(--slgo-title) !important;
}
.slgo-small-title, .slgo-small-title * {
	color: var(--slgo-small-title) !important;
}
.slgo-text-capitalized, .slgo-text-capitalized * {
	text-transform: capitalize;
}
.slgo-grey-bg {
	background-color: var(--slgo-grey-bg);
}
.grecaptcha-badge {
	display: none;
}
.btn {
	font-size: 16px;
	font-weight: 600;
	padding: 12px 25px 13px 30px;
	line-height: 25px;
	border-radius: 25px;
	border: 0 !important;
}
.btn.btn-no-arrow {
	padding: 13px 30px 14px 30px;
}
.btn:not(.btn-no-arrow)::after {
	content: "";
	display: inline-block;
	margin-left: 14px;
	border-top: 2px solid var(--slgo-light-text);
	border-right: 2px solid var(--slgo-light-text);
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
}
.btn.btn-only-icon {
	padding: 15px !important;
	border-radius: 50% !important;
	width: 55px !important;
	height: 55px !important;
	line-height: 25px;
	text-align: center !important;
}
.btn.btn-only-icon::before {
	margin: 0 !important;
}
.btn.btn-primary {
	background-color: var(--slgo-blue-btn) !important;
}
.btn.btn-secondary {
	background-color: var(--slgo-sky-btn) !important;
}
.navbar-top {
	background-color: var(--slgo-blue-nav);
	min-height: 56px;
}
.navbar-top .navbar-brand {
	margin: 0;
	padding: 0;
	width: 220px;
	text-align: center;
	position: relative;
}
.navbar-top .navbar-brand.logo-desktop {
	display: block;
}
.navbar-top .navbar-brand.logo-mobile {
	display: none;
}
.navbar-top .navbar-brand img {
	width: 180px;
}
.navbar-top .navbar-brand::after {
	content: "";
	display: block;
	background-color: var(--slgo-main-white);
	width: 100%;
	height: 77px;
	border-bottom-right-radius: 40px;
	border-bottom-left-radius: 40px;
	position: absolute;
	left: 0;
	top: -20px;
	z-index: -1;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.navbar-top li a {
	color: var(--slgo-light-text) !important;
	font-weight: 600 !important;
}
.navbar-top .navbar-toggler {
	border: 0 !important;
	padding: 0 !important;
	box-shadow: none !important;
}
.navbar-top .navbar-toggler .navbar-toggler-icon {
	/* temporaneo */
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.offcanvas.offcanvas-mobile-menu {
	background-color: var(--slgo-sky-offcanvas) !important;
	border: 0 !important;
}
.offcanvas.offcanvas-mobile-menu * {
	color: var(--slgo-light-text) !important;
}
.offcanvas.offcanvas-mobile-menu .btn-close {
	opacity: 1 !important;
	box-shadow: none !important;
}
.slgo-full-width-map {
	height: 400px;
}
.sticky-sidebar {
	top: 7rem;
}
.slgo-centers-list .slgo-center {
	border-radius: 20px;
	position: relative;
}
.slgo-centers-list .slgo-center .anchor-center {
	width: 100%;
	position: absolute;
	top: -70px;
	left: 0;
}
.slgo-centers-list .slgo-center .slgo-center-img {
	border-radius: 14px;
	overflow: hidden;
	position: relative;
}
.slgo-centers-list .slgo-center .slgo-center-img img {
	width: 100%;
}
.slgo-centers-list .slgo-center.slgo-vip-center .slgo-center-img::before {
	content: "";
	display: block;
	width: 80px;
	height: 80px;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	background-image: url(../../../../public/img/label-ads.png);
	background-size: 100% auto;
}
.slgo-centers-list .slgo-center.slgo-vip-center + .slgo-center:not(.slgo-vip-center) {
	margin-top: 4rem;
}
.slgo-centers-list .slgo-center.slgo-vip-center + .slgo-center:not(.slgo-vip-center)::before {
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	position: absolute;
	left: 0;
	top: -2rem;
	background: var(--slgo-grey-element);
}
.slgo-centers-list .slgo-center p {
	line-height: 22px;
}
.slgo-centers-list .slgo-center p::before {
	content: "";
	display: inline-block;
	flex-shrink: 0 !important;
	width: 20px;
	height: 20px;
}
.slgo-centers-list .slgo-center p::before {
	background-size: auto;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-mask-size: auto;
	mask-size: auto;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: center;
	mask-position: center;
}
.slgo-centers-list .slgo-center .slgo-distance::before {
	-webkit-mask-image: url(../../../../public/img/icons/location-dot-solid.svg);
	mask-image: url(../../../../public/img/icons/location-dot-solid.svg);
	background-color: var(--slgo-main-black) !important;
}
.slgo-centers-list .slgo-center .slgo-address::before {
	-webkit-mask-image: url(../../../../public/img/icons/location-crosshairs-solid.svg);
	mask-image: url(../../../../public/img/icons/location-crosshairs-solid.svg);
	background-color: var(--slgo-main-blue) !important;
}
.slgo-centers-list .slgo-center .slgo-center-type::before {
	-webkit-mask-image: url(../../../../public/img/icons/user-doctor-solid.svg);
	mask-image: url(../../../../public/img/icons/user-doctor-solid.svg);
	background-color: var(--slgo-main-green) !important;
}
.slgo-centers-list .slgo-center .slgo-description::before {
	-webkit-mask-image: url(../../../../public/img/icons/stethoscope-solid.svg);
	mask-image: url(../../../../public/img/icons/stethoscope-solid.svg);
	background-color: var(--slgo-main-orange) !important;
}
.slgo-centers-list .slgo-center .tags small {
	color: var(--slgo-light-text) !important;
	background-color: var(--slgo-grey-element) !important;
	padding: 4px 14px;
	line-height: 16px;
	text-transform: uppercase;
	font-size: 12px;
	border-radius: 12px;
	display: inline-block;
	margin-right: 4px;
	margin-bottom: 8px;
}
.pagination.slgo-centers-pagination .page-item a {
	background: none !important;
	border: 0 !important;
	color: var(--slgo-text) !important;
}
.pagination.slgo-centers-pagination .page-item.active a, .pagination.slgo-centers-pagination .page-item a:hover {
	color: var(--slgo-sky-link) !important;
}

.footer-container {
	background-color: var(--slgo-blue-footer) !important;
}
.footer-container .slgo-footer.slgo-footer-col-logo .footer-brand{
	width: 180px;
}
.footer-container .slgo-footer.slgo-footer-col-social a {
	width: 32px;
	height: 32px;
	display: inline-block;
}
.footer-container .slgo-footer.slgo-footer-col-social a::before {
	content: "";
	display: inline-block;
	width: 100%;
    height: 100%;
	background-size: auto;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-mask-size: auto;
	mask-size: auto;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: center;
	mask-position: center;
	background-color: var(--slgo-main-white) !important;
}
.footer-container .slgo-footer.slgo-footer-col-social a.link-linkedin::before {
	-webkit-mask-image: url(../../../../public/img/icons/linkedin.svg);
	mask-image: url(../../../../public/img/icons/linkedin.svg);
}
.footer-container .slgo-footer.slgo-footer-col-social a.link-instagram::before {
	-webkit-mask-image: url(../../../../public/img/icons/instagram.svg);
	mask-image: url(../../../../public/img/icons/instagram.svg);
}

.slider-top.slider-top-green {
	background-color: var(--slgo-green-slide) !important;
}
.slider-top.slider-top-yellow {
	background-color: var(--slgo-yellow-slide) !important;
}
.slider-top.slider-top-orange {
	background-color: var(--slgo-orange-slide) !important;
}
.slider-top.slider-top-grey {
	background-color: var(--slgo-grey-slide) !important;
}
.slider-top {
	background-size: auto 102%;
	background-size: cover !important;
	background-position: center;
}
.slider-top > .container > .row {
	height: auto;
}
.slider-top > .container > .row > div > img {
	height: 100%;
	margin: 0;
}

.slgo-filters-section {
	background-color: var(--slgo-sky-bg) !important;
}
.slgo-filters-section .nav.nav-tabs {
	border: 0 !important;
}
.slgo-filters-section .nav.nav-tabs .nav-link {
	border: 0 !important;
	background-color: transparent;
	color: var(--slgo-main-white) !important;
	opacity: 0.75;
}
.slgo-filters-section .nav.nav-tabs .nav-link.active, .slgo-filters-section .nav.nav-tabs .nav-link:hover {
	background-color: var(--slgo-sly-tab) !important;
	opacity: 1;
}
.slgo-filters-section .nav-tabContent {
	background-color: var(--slgo-sky-form) !important;
	border-radius: 12px !important;
}
.slgo-filters-section .form-check.form-check-inline .form-check-label {
	color: #fff;
	text-transform: uppercase;
	font-weight: 100;
	cursor: pointer;
}
.slgo-filters-section .form-check.form-check-inline .form-check-input {
	cursor: pointer;
	background: none;
	border: 2px solid #fff;
}
.slgo-filters-section .form-check.form-check-inline .form-check-input:checked {
	background: var(--slgo-main-white) !important;
}
.slgo-filters-section .col-search-input input {
	border: 0;
	height: 52px;
	padding: 0 25px;
	width: 100%;
	border-radius: 26px;
}

.single-center-content, .single-service-content {
	background-color: var(--slgo-sky-bg) !important;
}
.single-center-content.single-center-content-search-form {
	position: relative;
}
.single-center-content.single-center-content-search-form::before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 1;
	width: 540px;
	height: 540px;
	max-width: 60vw;
	max-height: 60vw;
	background-image: url(../../../../public/img/lens-background-element-white.png);
	background-size: cover;
	background-position: center;
}
.single-center-content.single-center-content-search-form > .container.slgo-container-search-center-exam {
	position: relative;
	z-index: 2;
}
.single-center-content .container-top, .single-service-content .container-top {
	background-color: var(--slgo-main-white) !important;
	padding-top: 5.5rem !important;
	border-radius: 26px;
	position: relative;
	overflow: hidden;
}
.single-center-content .container-top::before, .single-service-content .container-top::before {
	content: "";
	display: block;
	background-color: var(--slgo-green-slide) !important;
	width: 100%;
	height: 2.5rem;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
}
.single-center-content .container-top p, .single-service-content .container-top p {
	margin-bottom: 0;
}
.single-center-content .container-top .slgo-single-center-img {
	border-radius: 14px;
	overflow: hidden;
}
.single-center-content .container-top .slgo-single-center-img img {
	width: 100%;
}
.single-center-content .container-top .btn {
	background-color: var(--slgo-green-bg) !important;
	color: var(--slgo-blue-btn) !important;
}
.single-center-content .container-top .btn::after {
	border-top: 2px solid var(--slgo-blue-btn) !important;
	border-right: 2px solid var(--slgo-blue-btn) !important;
}

.single-center-content .slgo-container-search-center-exam input {
	border: 0;
	height: 52px;
	padding: 0 25px;
	width: 100%;
	border-radius: 26px;
}
.single-center-content .slgo-container-search-center-exam a.btn {
	width: 100%;
}

.single-center-content.single-center-content-info .slgo-pre-title {
	text-transform: uppercase;
}

.slgo-container-center-services div p {
	background-color: var(--slgo-grey-bg) !important;
	border-radius: 18px;
}
.slgo-container-center-services div p a {
	color: var(--slgo-blue-btn) !important;
}

.container.container-top.container-top-2::before {
	background-color: var(--slgo-main-yellow) !important;
}

.btn.slgo-btn-search::before {
	content: "";
	display: inline-block;
	width: 20px;
    height: 20px;
	vertical-align: text-bottom;
	margin-right: 8px;
	background-size: auto;
	background-position: center;
	background-repeat: no-repeat;
	/*background-image: url(../../../../public/img/icons/arrow-down-short-wide-solid.svg);*/
	/*-webkit-mask-image: url(../../../../public/img/icons/arrow-down-short-wide-solid.svg);*/
	/*mask-image: url(../../../../public/img/icons/arrow-down-short-wide-solid.svg);*/
	/*background-color: var(--slgo-main-white) !important;*/
	background-image: url('data:image/svg+xml,<svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/></svg>');
}

.slgo-btn-search.slgo-btn-search-home {
	background: var(--slgo-main-yellow) !important;
	color: var(--slgo-blue-btn) !important;
	font-weight: bold;
	padding: 12px 100px;
}
.btn.slgo-btn-search.slgo-btn-search-home::before {
	width: 28px;
    height: 28px;
	vertical-align: middle;
	background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg fill="%231b4c90" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 472" style="enable-background:new 0 0 512 472;" xml:space="preserve"><style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style><path class="st0" d="M291.4,384.4c-36.4-4.9-69.5-19.8-96.6-41.9L79.3,441.6l-34.2,30.1L0,419.5l84.1-72.7l65.8-56.8c-12.6-21.6-21.2-45.8-24.6-71.7h85.5c9.6,39.8,40.9,71.1,80.7,80.7V384.4z M291.4,0.3c-86.3,11.5-154.6,79.8-166.1,166.1h85.5c9.6-39.8,40.9-71.1,80.7-80.7V0.3z M345.9,383.6C432.2,372,500.5,303.7,512,217.4h-85.5c-9.6,39.8-40.9,71.1-80.7,80.7V383.6z M343.2,85.7c39.8,9.6,71.1,40.9,80.7,80.7h85.4C497.8,80.1,429.5,11.8,343.2,0.3V85.7z"/></svg>');
}

.slider-404 .btn.btn-primary.slgo-btn-404 {
	background: var(--slgo-main-yellow) !important;
	color: var(--slgo-blue-btn) !important;
	font-weight: bold;
	padding: 13px 100px;
	min-width: 160px;
}

#main .slider-top.slider-top-green {
	background-image: url(../../../../public/img/header-background-trova-centro-medico.jpg);
}
#main .slider-top.slider-top-yellow {
	background-image: url(../../../../public/img/header-background-prestazioni.jpg);
}
#main .slider-top.slider-top-orange {
	background-image: url(../../../../public/img/header-background-contatti.jpg);
}
#main .slider-top.slider-top-grey {
	background-image: url(../../../../public/img/header-background-community.jpg);
}

.cta-cooperate {
	background-image: url(../../../../public/img/background-hp-contatti.svg);
	background-color: var(--slgo-sky-bg);
	border-radius: 40px;
	background-size: cover;
	background-position: center;
}

.single-center-content.single-center-content-info .container .col-md-6 {
	min-height: 340px;
}
.single-center-content.single-center-content-info .container .col-md-6 .slgo-img-single-center {
	height: 100%;
	max-height: 406px;
	min-height: 406px;
	background-color: #fafafa;
	/* background-size: cover; */
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-origin: content-box;
	padding: 10px 15px;
	border-radius: 26px;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0px 2px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.btn.slgo-btn-single-center::before {
	content: "";
	display: inline-block;
	width: 20px;
    height: 20px;
	vertical-align: text-bottom;
	margin-right: 8px;
	background-size: auto;
	background-position: center;
	background-repeat: no-repeat;
}
.btn.slgo-btn-single-center.slgo-btn-phone::before {
	/*background-image: url(../../../../public/img/icons/phone-solid.svg);*/
	background-image: url('data:image/svg+xml,<svg fill="%231b4c90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>');
}
.btn.slgo-btn-single-center.slgo-btn-email::before {
	/*background-image: url(../../../../public/img/icons/envelope-solid.svg);*/
	background-image: url('data:image/svg+xml,<svg fill="%231b4c90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>');
}
.btn.slgo-btn-single-center.slgo-btn-website::before {
	/*background-image: url(../../../../public/img/icons/arrow-pointer-solid.svg);*/
	background-image: url('data:image/svg+xml,<svg fill="%231b4c90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 55.2V426c0 12.2 9.9 22 22 22c6.3 0 12.4-2.7 16.6-7.5L121.2 346l58.1 116.3c7.9 15.8 27.1 22.2 42.9 14.3s22.2-27.1 14.3-42.9L179.8 320H297.9c12.2 0 22.1-9.9 22.1-22.1c0-6.3-2.7-12.3-7.4-16.5L38.6 37.9C34.3 34.1 28.9 32 23.2 32C10.4 32 0 42.4 0 55.2z"/></svg>');
}

.slgo-max-w-906 {
	max-width: 906px;
}

.single-service-content.single-service-content-info .slgo-title {
	display: flex;
	align-items: center;
}
.single-service-content.single-service-content-info .slgo-title::before {
	content: "";
	display: inline-block;
	/*width: 115px;*/
    /*height: 115px;*/
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../../../../public/img/placeholder-prestazioni.png);
	vertical-align: middle;
	margin-right: 32px;
	flex: 0 0 115px;
	height: 115px;
}

.single-service-content.single-service-content-no-center .slgo-desc-service {
	display: flex;
	align-items: center;
}

.related-centers {
	background-color: var(--slgo-main-blue);
}
.related-centers .slgo-img-single-center-related-centers {
	background-color: var(--slgo-blue-nav);
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}
.related-centers .slgo-img-single-center-related-centers img {
	width: 100%;
	border-radius: 10px;
	object-fit: cover;
}
.related-centers .slgo-img-single-center-related-centers::before {
	content: "";
	display: block;
	width: 80px;
	height: 80px;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	background-image: url(../../../../public/img/label-ads.png);
	background-size: 100% auto;
}
.related-centers.slgo-single-center-related-centers {
	background-color: var(--slgo-sky-bg);
}
.related-centers.slgo-single-center-related-centers .container {
	position: relative;
	z-index: 3;
}
.related-centers.slgo-single-center-related-centers .container .row-grid {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	justify-items: stretch;
	column-gap: 10px;
}
.related-centers.slgo-single-center-related-centers .container .row-grid > div {
	width: 100% !important;
	padding: 0;
	min-width: 0;
	word-wrap: break-word;
	overflow-wrap: break-word;
	overflow: hidden;
	position: relative;
}
.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers {
	height: 100%;
	background-color: var(--slgo-main-blue);
	padding-bottom: 70px !important;
}
.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers .slgo-light-text a {
	color: var(--slgo-light-text) !important;
	font-weight: 600;
}
.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-contact-big-btn-related-center {
	display: none;
}
.related-centers.slgo-single-center-related-centers .slgo-img-single-center-related-centers::before {
	width: 60px;
	height: 60px;
	left: 0.5rem;
	top: 0.5rem;
}
.related-centers.slgo-single-center-related-centers .slgo-contact-related-center {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 4;
	display: flex;
	justify-content: space-evenly;
}
.related-centers.slgo-single-center-related-centers .slgo-contact-related-center .slgo-btn-single-center {
	background-color: var(--slgo-green-bg) !important;
}
.related-centers.slgo-single-center-related-centers .slgo-img-single-center-related-centers .slgo-center-img-bg {
	aspect-ratio: 1.4;
	background-size: cover;
	background-position: center;
}
.related-centers.slgo-single-center-related-centers .slgo-img-single-center-related-centers .slgo-center-img-bg img {
	display: none;
}

.about-search {
	background-color: var(--slgo-grey-bg);
}
.about-coverage {
	background-color: var(--slgo-main-blue);
}

.home-3-box .col-md-4 img {
	max-width: 280px;
}

.slider-home, .slider-404 {
	text-align: center;
	background-color: var(--slgo-sky-bg) !important;
	background-image: url(../../../../public/img/lens-background-element-white.png);
	background-size: auto 85%;
	background-repeat: no-repeat;
	background-position: bottom left;
	position: relative;
}
.slider-home .slgo-insalute p, .slider-404 .slgo-insalute p {
	color: var(--slgo-main-white) !important;
	margin-left: auto !important;
	margin-right: auto !important;
}
.slider-home .slgo-insalute p img, .slider-404 .slgo-insalute p img {
	width: auto;
	max-width: 100%;
	height: 40px;
}
.slider-home h1, .slider-404 h1 {
	color: var(--slgo-main-white) !important;
	text-align: center;
}
.slider-home h4, .slider-404 h4 {
	color: var(--slgo-main-white) !important;
	text-align: center;
}
.slider-home img, .slider-404 img {
	width: 90%;
	max-width: 400px;
}
.slider-home > small.slgo-go-down {
	position: relative;
	padding-bottom: 30px !important;
	display: inline-block;
}
.slider-home > small::after {
	content: "";
	display: inline-block;
	border-top: 2px solid var(--slgo-main-white);
	border-right: 2px solid var(--slgo-main-white);
	width: 8px;
	height: 8px;
	transform: rotate(135deg);
	position: absolute;
	left: 50%;
	top: 27px;
}

.slider-home .col-search-input.col-search-input-align-left {
	text-align: left;
}

.slider-404 .btn.slgo-btn-404::before {
	display: none !important;
}

.home-resources {
	background-color: var(--slgo-sky-bg) !important;
}
.home-resources .home-resources-box {
	border-radius: 20px;
	background-size: auto 80%;
	background-repeat: no-repeat;
	background-position: bottom left;
}
.home-resources .home-resources-box-yellow {
	background-color: var(--slgo-yellow-slide) !important;
	background-image: url(../../../../public/img/lens-background-element-grey.png);
}
.home-resources .home-resources-box-green {
	background-color: var(--slgo-green-slide) !important;
	background-image: url(../../../../public/img/lens-background-element-grey.png);
}

.slgo-input input {
	height: 52px;
	border-radius: 26px;
	padding: 0 7px 0 12px;
	text-transform: capitalize;
	border: 0;
	text-align: center;
}

h1 > img, h2 > img, h3 > img, h4 > img, h5 > img, h6 > img {
	height: 1.2em;
}

h1, h2 {
	font-family: 'Poppins';
}

.btn.slgo-white-btn {
	color: var(--slgo-blue-btn) !important;
	background: var(--slgo-main-white) !important;
}
.btn.slgo-white-btn:not(.btn-no-arrow)::after {
	border-top: 2px solid var(--slgo-blue-btn);
	border-right: 2px solid var(--slgo-blue-btn);
}

.col-search-input > .slgo-btn-search {
	min-width: 160px;
}

.slgo-join-us {
	background-image:url(../../../../public/img/hp-community.jpg);
	background-size: cover;
	background-position: center;
}

.slgo-reset-filters {
	cursor: pointer;
	color: var(--slgo-main-white);
	margin-top: -5px;
	margin-bottom: -10px;
	display: inline;
	width: auto;
	border-bottom: 1px solid rgba(0,0,0,0);
}
.slgo-reset-filters:hover {
	border-bottom: 1px solid var(--slgo-main-white);
}

.single-center-content-info-maps {
	z-index: 5;
}

.container-center-actions {
	background: var(--slgo-sky-offcanvas);
	border-radius: 20px;
	position: relative;
	z-index: 5;
}
.container-center-actions .btn.slgo-btn-single-center-action {
	background: var(--slgo-main-yellow) !important;
	color: var(--slgo-blue-btn) !important;
	width: 100%;
}
.container-center-actions .btn.slgo-btn-single-center-action b::before {
	content: "";
	display: inline-block;
	width: 18px;
	height: 18px;
	vertical-align: sub;
	margin-right: 6px;
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-color: var(--slgo-blue-btn) !important;
}
.container-center-actions .btn.slgo-btn-single-center-action.slgo-btn-phone b::before {
	-webkit-mask-image: url(../../../../public/img/icons/phone-solid.svg);
	mask-image: url(../../../../public/img/icons/phone-solid.svg);
	width: 18px;
}
.container-center-actions .btn.slgo-btn-single-center-action.slgo-btn-mobile b::before {
	-webkit-mask-image: url(../../../../public/img/icons/smartphone.svg);
	mask-image: url(../../../../public/img/icons/smartphone.svg);
	width: 12px;
}
.container-center-actions .btn.slgo-btn-single-center-action.slgo-btn-cup-phone b::before {
	-webkit-mask-image: url(../../../../public/img/icons/cross-phone.svg);
	mask-image: url(../../../../public/img/icons/cross-phone.svg);
	width: 22.5px;
}
.container-center-actions .btn.slgo-btn-single-center-action.slgo-btn-cup-mobile b::before {
	-webkit-mask-image: url(../../../../public/img/icons/cross-smartphone.svg);
	mask-image: url(../../../../public/img/icons/cross-smartphone.svg);
	width: 20px;
}
.container-center-actions .btn.slgo-btn-single-center-action.slgo-btn-email b::before {
	-webkit-mask-image: url(../../../../public/img/icons/envelope-solid.svg);
	mask-image: url(../../../../public/img/icons/envelope-solid.svg);
	width: 18px;
}
.container-center-actions .btn.slgo-btn-single-center-action.slgo-btn-website b::before {
	-webkit-mask-image: url(../../../../public/img/icons/arrow-pointer-solid.svg);
	mask-image: url(../../../../public/img/icons/arrow-pointer-solid.svg);
	width: 11px;
}

.container-h-100 {
	height: calc(100% - 5rem);
}

.single-service-content.single-service-content-all .slgo-service-card {
	background-image: url(../../../../public/img/lens-background-element-grey.png);
	background-size: 60% auto;
	background-repeat: no-repeat;
	background-position: bottom left;
}

.slgo-placeholder-icon {
	height: 24px !important;
	width: 24px !important;
	margin-right: 8px;
}

.slgo-span-logo {
	font-style: italic;
	color: var(--slgo-sky-bg) !important;
	font-weight: 700 !important;
}
.slgo-span-logo > span {
	color: var(--slgo-green-bg) !important;
	font-weight: 500 !important;
}

body .iubenda-tp-btn.iubenda-cs-preferences-link {
	position: absolute !important;
}

.signup-plans {
	background-color: var(--slgo-grey-bg);
}
.signup-plans .slgo-single-plan > img {
	border-top-right-radius: 26px;
	border-top-left-radius: 26px;
}
.signup-plans .slgo-single-plan > div {
	background: var(--slgo-main-white);
	border-bottom-right-radius: 26px;
	border-bottom-left-radius: 26px;
}
.signup-plans .slgo-single-plan > div h5 {
	text-transform: uppercase;
}

.signup-form {
	background: var(--slgo-main-blue);
}
.signup-form .signup-form-content {
	background: var(--slgo-main-white);
	border-radius: 26px;
}

.signup-form-content .form-input input, .signup-form-content .form-input textarea {
	width: 100%;
	padding: 8px 12px;
	border: 0;
	border-bottom: 1px solid var(--slgo-main-black);
	resize: none;
}

.slgo-autocomplete {
	position: relative;
}
.slgo-autocomplete > div {
	width: 100%;
}
.slgo-autocomplete > div > div {
	border-radius: 8px !important;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 12px !important;
	background: var(--slgo-main-white) !important;
	padding: 8px 0 !important;
	font-size: 100% !important;
	position: absolute !important;
	overflow: auto !important;
	max-height: 250px !important;
	left: 0 !important;
	top: 60px !important;
	min-width: 0 !important;
	width: 100% !important;
	z-index: 99999 !important;
}
.slgo-autocomplete input {
	width: 100%;
	text-align: left;
	cursor: pointer;
}
.slgo-autocomplete .slgo-autocomplete-dropdown-item {
	padding: 5px 10px;
	cursor: pointer;
	color: var(--slgo-main-black) !important;
	background: var(--slgo-main-white) !important;
}
.slgo-autocomplete .slgo-autocomplete-dropdown-item:hover {
	color: var(--slgo-main-white) !important;
	background: var(--slgo-main-blue) !important;
}
.slgo-autocomplete.slgo-autocomplete-home input {
	padding-left: 23px;
	font-weight: 500;
	color: var(--slgo-main-black) !important;
}
.slgo-autocomplete.slgo-autocomplete-home input::placeholder {
	background-size: auto 24px;
	background-repeat: no-repeat;
	background-position: left top;
	height: 24px;
	text-transform: initial !important;
	color: var(--slgo-main-grey) !important;
}
.slgo-autocomplete.slgo-autocomplete-home.slgo-autocomplete-compiled input::placeholder {
	color: var(--slgo-main-black) !important;
}
.slgo-autocomplete.slgo-autocomplete-home input:focus::placeholder {
	background: none !important;
	color: var(--slgo-main-white) !important;
}
.slgo-autocomplete.slgo-autocomplete-google-places input::placeholder {
	background-image: url(../../../../public/img/icons/location-dot-solid-grey.svg);
}
.slgo-autocomplete.slgo-autocomplete-category input::placeholder {
	background-image: url(../../../../public/img/icons/magnifying-glass-solid-grey.svg);
}

.single-service-content .slgo-desc-service .slgo-desc-service-content {
	/*overflow: hidden;*/
	/*width: 100%;*/
	/*white-space: nowrap;*/
	/*overflow: hidden;*/
}
.single-service-content .slgo-desc-service .slgo-desc-service-content .slgo-title {
	/*white-space: nowrap;*/
	/*overflow: hidden;*/
	/*text-overflow: ellipsis;*/
	display: inline-block;
	vertical-align: top;
	white-space: normal;
	text-overflow: ellipsis;
	max-width: 100%;
	overflow: hidden;
}

.slgo-mobile {
	display: none !important;
}

.slgo-near-to b::before {
	content: "";
	display: inline-block;
	flex-shrink: 0 !important;
	margin-right: 4px;
	width: 20px;
	height: 20px;
	vertical-align: text-bottom;
	background-size: auto;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-mask-size: auto;
	mask-size: auto;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: center;
	mask-position: center;
	-webkit-mask-image: url(../../../../public/img/icons/location-dot-solid.svg);
	mask-image: url(../../../../public/img/icons/location-dot-solid.svg);
	background-color: var(--slgo-main-green) !important;
}

.slgo-img-h-auto {
	max-width: 100%;
	height: auto;
}

.single-service-content.single-service-content-info .slgo-service-card .single-center-contacts p {
	display: inline-block;
}
.single-service-content.single-service-content-info .slgo-service-card .single-center-contacts .slgo-btn-single-center-options {
	background: var(--slgo-main-yellow) !important;
	color: var(--slgo-blue-btn) !important;
}
.single-service-content.single-service-content-info .slgo-service-card .single-center-contacts .slgo-btn-single-center-options::before {
	content: "";
	display: inline-block;
	width: 18px;
	height: 18px;
	vertical-align: sub;
	margin-right: 6px;
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-color: var(--slgo-blue-btn) !important;
	-webkit-mask-image: url(../../../../public/img/icons/stethoscope-solid.svg);
	mask-image: url(../../../../public/img/icons/stethoscope-solid.svg);
	width: 20px;
}

.single-center-contacts.single-center-contacts-small-card .btn {
	background-color: var(--slgo-green-bg) !important;
	color: var(--slgo-blue-btn) !important;
}

.slgo-centers-list .slgo-center .btn.btn-only-icon.slgo-btn-single-center {
	background-color: var(--slgo-green-bg) !important;
	color: var(--slgo-blue-btn) !important;
}

p span.required {
	font-size: 19px;
	color: var(--slgo-red-required);
}

.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 25px 0;
	background: var(--slgo-main-white);
	/*position: fixed;*/
	/*top: 0;*/
	/*left: 0;*/
	/*right: 0;*/
	/*bottom: 0;*/
	/*z-index: 99999;*/
}
.loader-container .loader-img {
	width: 100px;
	height: 100px;
}
.loader-container.loader-container-map {
	padding: 0;
	height: 350px;
	background: var(--slgo-main-skeleton-bg);
}

.signup-form-content .form-checkbox input {
	height: 20px;
	width: 20px;
	margin-right: 8px;
	cursor: pointer;
	vertical-align: sub;
}

.slgo-ads-center .slgo-single-ads-center {
	background-color: var(--slgo-main-blue);
	border-radius: 10px;
	padding: 8px !important;
	display: flex;
	flex-direction: column;
}
.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img:after {
	content: "";
	display: block;
	width: 60px;
	height: 60px;
	position: absolute;
	left: 8px;
	top: 8px;
	z-index: 1;
	background-image: url(../../../../public/img/label-ads.png);
	background-size: 100% auto;
}
.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img a {
	aspect-ratio: 1.4;
	border-radius: 6px;
	background-color: #fafafa;
	/* background-size: cover; */
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-origin: content-box;
	padding: 5px 8px;
}
.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-title {
	display: flex;
	align-items: center;
	flex: 1;
	text-align: center;
	justify-content: center;
}
.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-title h6 a {
	font-weight: 600 !important;
}
.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-icons {
	display: flex;
	justify-content: space-evenly;
}
.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-icons .btn {
	background-color: var(--slgo-green-bg) !important;
}

.single-service-content-info > .container {
	position: relative;
	z-index: 5;
}
.single-service-content-info .slgo-service-card .single-center-contacts .btn {
	background-color: var(--slgo-main-yellow) !important;
	color: var(--slgo-blue-btn) !important;
}

.show-on-small {
	display: block !important;
}


